import { Flex } from '@mantine/core'

import { useScreenSize } from '~/hooks'

import { SvgIcon, SvgIconType } from './image/SvgIcon'
import { Typography } from './text/Typography'
import { useEffect, useState } from 'react'
import { ThatchGPTBanner } from './ThatchGPTBanner'

const ThatchGPTInteractiveButton = ({ location }: { location?: string }) => {
    const { isMobileScreen } = useScreenSize()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const lastShown = parseInt(localStorage.getItem("thatchpgt_interactive_banner_auto_show") || "0");
        const now = Date.now()
        const shouldShow = (now - lastShown) > (5 * 60 * 60 * 1000);
        console.log('--ThatchGPT Auto Show--', shouldShow)
        if (shouldShow) {
            setTimeout(() => {
                setOpen(true)
            }, 3000);
        }
    })

    const close = () => {
        setOpen(false)
        const now = Date.now()
        localStorage.setItem("thatchpgt_interactive_banner_auto_show", now.toString());

    }

    return (<>
        <div
            style={{
                position: 'fixed',
                bottom: (isMobileScreen ? 16 : 32),
                right: (isMobileScreen ? 16 : 32),
                background: 'linear-gradient(93deg, #D05BB9 31.26%, #D16EDA 70.71%)',
                borderRadius: 500,
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                display: 'block',
                cursor: 'pointer',
            }}
            onClick={close}
        >
            <Flex
                p="10px 10px"
                align="center"
                gap={6}
            >
                {open ?
                    <>
                        <SvgIcon type={SvgIconType.CLOSE_ICON} fill='appWhite.0' />
                    </> :
                    <div className='px-1.5 flex items-center gap-1.5'>
                        <SvgIcon type={SvgIconType.TRY_THATCH_BUTTON_ICON} />
                        <Typography
                            color="appWhite.0"
                            variant="button_medium"
                            sx={{ lineHeight: '120%', fontSize: '20px !important' }}
                        >
                            Ask ThatchGPT
                        </Typography>
                    </div>}
            </Flex>
        </div>
        <div className={`fixed bottom-20 right-0 md:bottom-24 md:right-8 rounded-lg shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] w-full md:w-[420px]  transition-all origin-bottom-right ${open ? 'opacity-100 scale-100' : 'opacity-0 scale-75'}`}>
            <ThatchGPTBanner location={location} />
        </div>
    </>)
}

export default ThatchGPTInteractiveButton
