import { SSRPropsContext } from 'next-firebase-auth'

import {
  createPlaceRequestParser,
  derivePlaceType,
  googlePlaceDetailsParser,
  googlePlaceSummaryParser,
  updatePlaceRequestParser,
} from '~/components/places/utils'
import { GeocoderResponse, PlaceResult } from '~/components/shared/googlemaps/types'
import { fetchBookingUrl } from '~/endpoints/booking'
import { WeatherLocation, parseFactory } from '~/endpoints/model'
import {
  AllPlaceSummarySchema,
  GoogleReverseGeocodeResponse,
  PlaceDetailsSchema,
  PlaceDetailsType,
  PlaceLinkType,
  PlaceSummaryType,
} from '~/endpoints/model/places'
import thatchConfig from '~/thatch-config'
import { getAuthClient } from '~/utils/apiClient'
import { nextSessionToken, sessionToken } from '~/utils/sessionToken'
import { SSRAuthToken } from '~/utils/ssr'
import { LocationPayloadType } from '~/utils/types'

const allPlaceSummaryParser = parseFactory(AllPlaceSummarySchema, 'AllPlaceSummary')
const placeDetailsParser = parseFactory(PlaceDetailsSchema, 'PlaceDetails')

const parsePlaceDetailsResponse = (data: unknown) => {
  const placeDetails = placeDetailsParser(data)
  placeDetails.type = derivePlaceType(
    placeDetails.type,
    placeDetails.google.types,
    placeDetails.name
  )
  return placeDetails
}

export const fetchPlacePredictions = async (
  query: string,
  locationbias: string | undefined,
  sessionTokenParam: string,
  placeTypes?: string
) => {
  console.log('fetchPlacePredictions', sessionTokenParam)
  const httpClient = getAuthClient(undefined, true)
  const endpoint = `/api/proxy/maps/place/autocomplete/json?input=${query}${
    placeTypes ? `&types=${placeTypes}` : ''
  }&locationbias=${locationbias}`//&sessiontoken=${sessionTokenParam}`

  try {
    const response = await httpClient.get(endpoint)
    if (response.data.status === 'OK') {
      return response.data.predictions
    }
    return []
  } catch (error) {
    console.error(`Error get place predictions: Query = ${query}`)
    return []
  }
}

export const fetchPlaceRegionsPredictions = async (query: string, sessionTokenParam: string) => {
  const httpClient = getAuthClient(undefined, true)
  console.log('fetchPlaceRegionsPredictions', sessionTokenParam)
  const endpoint = `/api/proxy/maps/place/autocomplete/json?input=${query}&types=(regions)`//&sessiontoken=${sessionTokenParam}`
  try {
    const response = await httpClient.get(endpoint)
    if (response.data.status === 'OK') {
      return response.data.predictions
    }
    return []
  } catch (error) {
    console.error(`Error get place regions predictions: Query = ${query}`)
    return []
  }
}

export const fetchSearchPlaceDetails = async (
  placeId: string,
  context?: SSRPropsContext
): Promise<LocationPayloadType> => {
  const isSsr = !!context
  const httpClient = getAuthClient(context?.user?.getIdToken, !isSsr)
  const base = isSsr ? 'https://maps.googleapis.com/maps/api' : '/api/proxy/maps'
  const key = isSsr ? `&key=${thatchConfig.mapApiKey}` : ''
  const endpoint = `${base}/place/details/json?place_id=${placeId}&fields=name,geometry,address_components,formatted_address,type&sessiontoken=${sessionToken}&language=en&region=en${key}`
  try {
    const response = await httpClient.get(endpoint)
    if (response.data.status === 'OK' && response.data.result) {
      const place = response.data.result as PlaceResult
      return {
        formatted_address: place.formatted_address,
        geometry: place.geometry,
        place_id: placeId,
        types: place.types || [],
        address_components: place.address_components || [],
        name: place.name || '',
        home: false,
      }
    }
    // eslint-disable-next-line @typescript-eslint/return-await
    return Promise.reject(new Error('No Place Location found'))
  } catch (error) {
    console.error(`Error getting place location by place id. Query = ${placeId}`)
    throw error
  } finally {
    nextSessionToken()
  }
}

export const fetchPlaceAddressByPlaceId = async (
  placeName: string,
  placeId: string
): Promise<WeatherLocation> => {
  const httpClient = getAuthClient(undefined, true)
  const endpoint = `/api/proxy/maps/geocode/json?place_id=${placeId}&language=en&region=en`
  try {
    const response = await httpClient.get<GeocoderResponse>(endpoint)
    if (response.status == 200 && response.data.results.length > 0) {
      const place = response.data.results[0].geometry.location
      return {
        name: placeName,
        lat: place.lat as unknown as number,
        long: place.lng as unknown as number,
      }
    }
    throw new Error('No Place Location found')
  } catch (error) {
    console.error(`Error getting place address by place id. Query = ${placeId}`)
    throw error
  }
}

export const fetchPlaceAddressByLatLng = async (
  lat: number,
  lng: number
): Promise<GoogleReverseGeocodeResponse> => {
  const httpClient = getAuthClient(undefined, true)
  const endpoint = `/api/proxy/maps/geocode/json?latlng=${lat},${lng}&language=en&region=en`

  const response = await httpClient.get<GeocoderResponse>(endpoint)
  if (response.status == 200 && response.data.results.length > 0) {
    const place = response.data.results[0]
    return {
      placeId: place.place_id,
      lat: place.geometry.location.lat as unknown as number,
      long: place.geometry.location.lng as unknown as number,
      plusCode: place.plus_code?.global_code ?? null,
    }
  }
  throw new Error('No Place Address found')
}

export const fetchGoogleMapPlaceSummary = async (place_id: string, sessionTokenParam: string) => {
  const httpClient = getAuthClient(undefined, true)
  const endpoint = `/api/proxy/maps/place/details/json?place_id=${place_id}&fields=place_id,name,geometry,photo,type&sessiontoken=${sessionTokenParam}&language=en&region=en`

  const response = await httpClient.get(endpoint)
  if (response.data.status === 'OK') {
    return googlePlaceSummaryParser(response.data.result)
  }
  return null
}

export const fetchGoogleMapPlaceDetails = async (place_id: string, sessionTokenParam: string) => {
  const httpClient = getAuthClient(undefined, true)
  const endpoint = `/api/proxy/maps/place/details/json?place_id=${place_id}&fields=place_id,name,formatted_address,geometry,international_phone_number,website,opening_hours,photo,type&sessiontoken=${sessionTokenParam}&language=en&region=en`

  const response = await httpClient.get(endpoint)
  if (response.data.status === 'OK') {
    return googlePlaceDetailsParser(response.data.result)
  }
  return null
}

export const fetchGoogleMapPlaceDetailsForAiGuide = async (
  place_id: string,
  sessionTokenParam: string,
  auth?: SSRAuthToken
) => {
  const httpClient = getAuthClient(auth, true)
  const endpoint = `/api/proxy/maps/place/details/json?place_id=${place_id}&fields=geometry&sessiontoken=${sessionTokenParam}&language=en&region=en`

  const response = await httpClient.get(endpoint)
  if (response.data.status === 'OK') {
    return response.data.result
  }
  return null
}

export const fetchAllPlaceSummary = async (auth?: SSRAuthToken) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get('/places/v2')
  const allPlaceSummary = allPlaceSummaryParser(res.data)
  allPlaceSummary.forEach(summary => {
    summary.type = derivePlaceType(summary.type, summary.google.types, summary.name)
  })
  return allPlaceSummary
}

export const fetchPlaceDetails = async (id: number) => {
  const httpClient = getAuthClient()
  const res = await httpClient.get(`/places/v2/${id}`)
  return parsePlaceDetailsResponse(res.data)
}

/**
 * Used by Board unlocked view when place blocks are viewed.
 * We dont need to fetch the google details at this time.
 */
export const fetchPreviewPlaceDetails = async (id: number) => {
  const httpClient = getAuthClient()
  const res = await httpClient.get(`/places/v2/${id}`)
  return parsePlaceDetailsResponse(res.data)
}

export const deletePlaceImage = async (placeId: number, imageId: number) => {
  const httpClient = getAuthClient()
  const res = await httpClient.delete(`/places/v2/${placeId}/image/${imageId}`)
  return parsePlaceDetailsResponse(res.data)
}

export const addPlacePhoto = async (placeId: number, url: string) => {
  const httpClient = getAuthClient()
  const res = await httpClient.post(`/places/v2/${placeId}/photos/url?url=${url}`)
  return parsePlaceDetailsResponse(res.data)
}

export const createPlace = async (placeDetails: PlaceSummaryType) => {
  const updatedPlaceDetails = placeDetails
  if (placeDetails.type == 'stay') {
    try {
      updatedPlaceDetails.bookingUrl = await fetchBookingUrl(placeDetails)
    } catch (err) {
      console.error(err)
      // TODO(shane): remove this once we are happy to not capture it in Sentry.
      // captureSentryException(err, scope => {
      //   scope.setTransactionName(`Error looking up booking.com url for ${placeDetails.name}`)
      //   return scope
      // })
    }
  }
  const createPlaceRequest = createPlaceRequestParser(updatedPlaceDetails)
  const httpClient = getAuthClient()
  const res = await httpClient.post(`/places/v2`, createPlaceRequest)
  const newPlaceDetails = parsePlaceDetailsResponse(res.data)

  // use google info from the input place details
  return {
    ...newPlaceDetails,
    google: {
      ...placeDetails.google,
    },
  }
}

export const deletePlace = async (placeId: number) => {
  const httpClient = getAuthClient()
  await httpClient.delete(`/places/v2/${placeId}`)
}

export const updateBookingUrl = async (place: PlaceSummaryType, bookingUrl: string | null) => {
  if (place.type == 'stay') {
    try {
      const httpClient = getAuthClient()
      const bookingUrlRes = await httpClient.put(
        `/places/v2/${place.id}/bookingurl?url=${bookingUrl}`
      )
      return parsePlaceDetailsResponse(bookingUrlRes.data)
    } catch (err) {
      console.error(err)
      // TODO(shane): remove this once we are happy to not capture it in Sentry.
      // captureSentryException(err, scope => {
      //   scope.setTransactionName(`Error updating booking.com url for ${place.name}`)
      //   return scope
      // })
    }
  }

  return fetchPlaceDetails(place.id ?? 0)
}

export const changePlaceType = async (place: PlaceSummaryType, placeType: string) => {
  const httpClient = getAuthClient()
  const res = await httpClient.put(`/places/v2/${place.id}/type?type=${placeType}`)
  let updatedPlaceDetails: PlaceDetailsType = parsePlaceDetailsResponse(res.data)
  // fetch booking.com url if place type changed to "stay"
  if (updatedPlaceDetails.type == 'stay') {
    try {
      const url = await fetchBookingUrl(updatedPlaceDetails)
      updatedPlaceDetails = await updateBookingUrl(updatedPlaceDetails, url)
    } catch (err) {
      console.error(`Error looking up booking.com url for %s: %s`, place.name, err)
      // TODO(shane): remove this once we are happy to not capture it in Sentry.
      // captureSentryException(err, scope => {
      //   scope.setTransactionName(`Error looking up booking.com url for ${place.name}`)
      //   return scope
      // })
    }
  } else {
    updatedPlaceDetails.bookingUrl = undefined
  }
  return updatedPlaceDetails
}

export const updatePlace = async (placeId: number, placeDetails: PlaceSummaryType) => {
  const updatePlaceRequest = updatePlaceRequestParser(placeDetails)
  const httpClient = getAuthClient()
  const res = await httpClient.put(`/places/v2/${placeId}`, updatePlaceRequest)
  return parsePlaceDetailsResponse(res.data)
}

export const addLink = async (placeId: number, link: PlaceLinkType) => {
  const httpClient = getAuthClient()
  const res = await httpClient.post(`/places/v2/${placeId}/links`, link)
  return parsePlaceDetailsResponse(res.data)
}

export const deleteLink = async (placeId: number, linkId: number) => {
  const httpClient = getAuthClient()
  const res = await httpClient.delete(`/places/v2/${placeId}/links/${linkId}`)
  return parsePlaceDetailsResponse(res.data)
}
