import { Box, Flex, Tabs, createStyles } from '@mantine/core'
import { useScrollIntoView } from '@mantine/hooks'
import { withUserTokenSSR } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { Layout } from '~/components/layout/Layout'
import StickySideLayout from '~/components/shared/layouts/StickySideLayout'
// import { TitleItalic } from '~/components/shared/text/Title'
import { Typography } from '~/components/shared/text/Typography'
import ThatchGPTInteractiveButton from '~/components/shared/ThatchGPTInteractiveButton'
import ProfileAbout from '~/components/user/ProfileAbout'
import { ProfileGuides } from '~/components/user/ProfileGuides'
import { ProfileIntro } from '~/components/user/ProfileIntro'
import ProfileServices from '~/components/user/ProfileServices'
import { useAnalytics } from '~/context'
import { fetchAllPublishedBoardByUser } from '~/endpoints/board'
import { ProfileDetail, emptyAllPublishedBoardByUser } from '~/endpoints/model'
import { fetchProfileSummary } from '~/endpoints/user'
import { useScreenSize } from '~/hooks'
import { ProfileSeoWrapper } from '~/seo/ProfileSeoWrapper'
import { MAX_SCREEN_WIDTH } from '~/utils/constants'

type SSRContext = {
  username: string
}

type Props = {
  profileDetail: ProfileDetail
}

const useStyle = createStyles(theme => ({
  parenContainer: {
    height: '100%',
  },
  coverContainer: {
    height: '60vh',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      height: '30vh',
    },
  },
  markDownText: {
    a: {
      textDecoration: 'underline',
    },
  },
  introContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'calc(-1 * (50vh - 155px))',
    marginBottom: 40,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      marginTop: 'calc(-1 * (50vh - 175px))',
      marginBottom: 20,
    },
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      marginTop: 'calc(-1 * (50vh - 300px))',
      marginBottom: 20,
    },
  },
  tab: {
    padding: 0,
    width: 180,
    ':hover': {
      backgroundColor: 'transparent',
    },
    '&[data-active]': {
      borderBottom: `2px solid ${theme.colors.appBlack}`,
    },
  },
  iconButton: {
    width: 36,
    height: 36,
    borderRadius: 32,
    borderColor: theme.colors.appPlaceholder,
    color: theme.colors.appBlack,
    cursor: 'pointer',
    ':hover': { backgroundColor: 'transparent' },
  },
  tabList: {
    height: 64,
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
  guidesContainer: {
    paddingLeft: 88,
    paddingRight: 88,
    marginBottom: 100,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      paddingLeft: 9,
      paddingRight: 9,
      marginBottom: 80,
    },
  },
  guideHeader: {
    paddingBottom: 34,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      paddingBottom: 16,
    },
  },
}))

const ProfileView = (props: Props) => {
  const { profileDetail } = props
  const { isMobileScreen, isTabletScreen } = useScreenSize()
  const { classes } = useStyle()
  const thatchSegment = useAnalytics()
  const router = useRouter()
  const bioSectionRef = useRef<HTMLDivElement>(null)
  const sideSectionRef = useRef<HTMLDivElement>(null)
  const [showMore, setShowMore] = useState<boolean>(false)

  const showServicesTab =
    (profileDetail.consultation && !profileDetail.consultation.disabled) ||
    (profileDetail.itinerary && !profileDetail.itinerary.disabled) ||
    (profileDetail.recs && !profileDetail.recs.disabled)

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 65,
    duration: 400,
  })

  const [initialBioHeight, setInitialBioHeight] = useState<number | undefined>()
  const [sideSectionHeight, setSideSectionHeight] = useState<number | undefined>()
  const [activeTab, setActiveTab] = useState<string | null>(showServicesTab ? 'guides' : 'guides')
  const containerRef = useRef<HTMLDivElement>(null)

  const isSeller = profileDetail.stripeSellerId

  useEffect(() => {
    setActiveTab(showServicesTab ? 'guides' : 'guides')
  }, [profileDetail])

  const handleClickBio = () => {
    if (activeTab !== 'about') {
      setActiveTab('about')
    }
    scrollIntoView({ alignment: 'start' })
  }

  useEffect(() => {
    const height = sideSectionRef.current?.offsetHeight
    if (height || sideSectionHeight !== height) {
      setSideSectionHeight(height)
    }
  }, [sideSectionRef.current])

  useEffect(() => {
    const height = bioSectionRef.current?.offsetHeight
    if (height && !initialBioHeight) {
      setInitialBioHeight(height)
    }
  }, [bioSectionRef.current, showMore])

  useEffect(() => {
    thatchSegment.trackMiscEvent('profile_viewed', {
      name: profileDetail.name,
      userName: profileDetail.username,
      servicesOffered: [profileDetail.recs, profileDetail.itinerary, profileDetail.consultation].filter(s => !!s),
      recs: profileDetail.recs,
      itinerary: profileDetail.itinerary,
      consultation: profileDetail.consultation,
      instagram: profileDetail.instagram,
      tiktok: profileDetail.tiktok,
      youtube: profileDetail.youtube,
      website: profileDetail.website,
      bio: profileDetail.bio,
      reviews: profileDetail.reviews,
      rating: profileDetail.rating,
    })
  },[profileDetail.username]);

  const TabTitle = useCallback(
    ({ title, tab }: { title: string; tab: string }) => {
      return (
        <Typography
          variant="body1"
          sx={{ color: activeTab === tab ? 'appBlack.0' : '#595959' }}
        >
          {title}
        </Typography>
      )
    },
    [activeTab]
  )

  const handleTabChange = (value: string) => {
    setActiveTab(value)
  }

  return (
    <ProfileSeoWrapper profile={profileDetail}>
      <Flex
        ref={containerRef}
        px={isMobileScreen ? 0 : 64}
        maw={MAX_SCREEN_WIDTH}
        mx="auto"
        sx={{ position: 'relative' }}
        direction={isMobileScreen || isTabletScreen ? 'column' : 'row'}
      >
        <StickySideLayout
          reverse
          mobileFooterSx={{ paddingLeft: 16, paddingRight: 16 }}
          stickyContentWrapperRef={sideSectionRef}
          scrollableContent={
            <Box
              pt={40}
              w="100%"
            >
              {/* {showServicesTab && !isMobileScreen && (
                <ProfileServices profileDetails={profileDetail} />
              )} */}

              <Flex
                direction="column"
                gap={8}
                w="100%"
              >
                {!isMobileScreen && (
                  <>
                    {/* <Title order={2} variant="titleSerif"><TitleItalic>Guides</TitleItalic></Title> */}
                    <ProfileGuides profile={profileDetail} />
                  </>
                )}
              </Flex>
            </Box>
          }
          stickyContent={
            <Box ref={sideSectionRef}>
              <ProfileIntro
                profile={profileDetail}
                onProfileEdited={() => {
                  router.replace(router.asPath) // refresh page on profile editing
                }}
                onClickBio={handleClickBio}
                showMoreBio={showMore}
                setShowMoreBio={setShowMore}
              />

              {isMobileScreen ? (
                <Tabs
                  ref={targetRef}
                  value={activeTab}
                  onTabChange={handleTabChange}
                  styles={{ tabsList: { marginLeft: 16, marginRight: 16 } }}
                  classNames={{ tab: classes.tab, tabsList: classes.tabList }}
                >
                  <Tabs.List
                    position="left"
                    sx={{ gap: 16, height: 52 }}
                  >
                    <Tabs.Tab
                      value="guides"
                      w="min-content"
                    >
                      <TabTitle
                        title="Guides"
                        tab="guides"
                      />
                    </Tabs.Tab>
                    {showServicesTab && false && (
                      <Tabs.Tab
                        value="services"
                        w="min-content"
                      >
                        <Flex gap={4}>
                          <TabTitle
                            title="Services"
                            tab="services"
                          />
                          <Flex
                            bg="appFuscia.0"
                            align="center"
                            justify="center"
                            sx={{ width: 37, height: 19, borderRadius: '27px' }}
                          >
                            <Typography
                              variant="tag"
                              sx={{ fontSize: '14px !important' }}
                              color="appWhite.0"
                            >
                              New
                            </Typography>
                          </Flex>
                        </Flex>
                      </Tabs.Tab>
                    )}

                    <Tabs.Tab
                      value="about"
                      w="min-content"
                    >
                      <TabTitle
                        title="About"
                        tab="about"
                      />
                    </Tabs.Tab>
                  </Tabs.List>

                  {showServicesTab && false && (
                    <Tabs.Panel
                      value="services"
                      pt="xs"
                    >
                      <Box mx={16}>
                        <ProfileServices profileDetails={profileDetail} />
                      </Box>
                    </Tabs.Panel>
                  )}

                  <Tabs.Panel
                    value="guides"
                    pt="xs"
                    px="md"
                  >
                    <ProfileGuides profile={profileDetail} />
                  </Tabs.Panel>

                  <Tabs.Panel
                    value="about"
                    pt="lg"
                  >
                    <ProfileAbout
                      bioSectionRef={bioSectionRef}
                      profileDetail={profileDetail}
                      setShowMore={setShowMore}
                      showMore={showMore}
                    />
                  </Tabs.Panel>
                </Tabs>
              ) : (
                <ProfileAbout
                  bioSectionRef={bioSectionRef}
                  profileDetail={profileDetail}
                  setShowMore={setShowMore}
                  showMore={showMore}
                />
              )}
            </Box>
          }
        />

        {/* {!isMobileScreen && (
          <Divider
            orientation="vertical"
            h={containerRef.current?.clientHeight}
            color="rgba(0,0,0,0.1)"
            mx={56}
          />
        )} */}
      </Flex>
      <ThatchGPTInteractiveButton />
    </ProfileSeoWrapper>
  )
}

ProfileView.getLayout = function getLayout(page: ReactElement, props: Props) {
  const {
    profileDetail: { photo, name },
  } = props
  return <Layout hideFooter>{page}</Layout>
}

export const getServerSideProps = withUserTokenSSR()(async (context: any) => {
  const { username } = context.query as SSRContext
  const authUser = context.user
  const tasksArray = []
  const profileHandle = username.length > 0 && !username.startsWith('@') ? `@${username}` : username

  const profileSummaryTask = fetchProfileSummary(profileHandle, authUser?.getIdToken).catch(_ => {
    return null
  })
  tasksArray.push(profileSummaryTask)

  const publishedBoardsTask = fetchAllPublishedBoardByUser(
    profileHandle,
    authUser?.getIdToken
  ).catch(e => {
    console.warn(`Error getting all published for user ${profileHandle}: ${e}`)
    return { ...emptyAllPublishedBoardByUser }
  })
  tasksArray.push(publishedBoardsTask)

  const [profileSummary, publishedBoards] = await Promise.all(tasksArray)
  if (profileSummary == null) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      profileDetail: {
        ...profileSummary,
        publishedBoards,
      },
    },
  }
})

export default ProfileView
