import ThatchGPTButton from "./ThatchGPTButton";
import { Input } from '@mantine/core';
import { SvgIcon, SvgIconType } from './image/SvgIcon'
import { SvgIconNative, SvgIconType as SvgIconNativeType } from '../shared/image/SvgIconNative'
import { Typography } from "./text/Typography";
import { IconChevronLeft, IconChevronRight } from '@tabler/icons'
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useAnalytics } from "~/context";

export function ThatchGPTBanner({ location }: { location?: string }) {
    const router = useRouter()
    const thatchSegment = useAnalytics()
    const [inputValue, setInputValue] = useState('')

    const containerRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)

    const [contentOffset, setContentOffset] = useState(0)
    const [scrollWidth, setScrollWidth] = useState(0)

    useEffect(() => {
        const containerWidth = containerRef.current?.offsetWidth || 0
        const contentWidth = contentRef.current?.scrollWidth || 0
        const scrollWidth = contentWidth - containerWidth
        setScrollWidth(scrollWidth)
    }, [containerRef.current?.offsetWidth, contentRef.current?.scrollWidth])

    function scrollableContent(direction: 'left' | 'right') {
        if (direction === 'left') {
            setContentOffset(Math.max(0, contentOffset - 250))
        } else {
            console.log('scrollWidth', scrollWidth)
            setContentOffset(Math.min(scrollWidth, contentOffset + 250))
        }
    }

    function handleQuerySubmit(query: string) {
        thatchSegment.trackMiscEvent('thatchgpt_banner_query_submit', { query })
        router.push({
            pathname: '/thatchgpt',
            query: { prompt: query },
        })
    }

    return (
        <div className="bg-app-paper rounded-lg px-4 py-10 md:px-12 flex flex-col items-center gap-6">
            <ThatchGPTButton inline />
            <Input icon={
                <SvgIcon type={SvgIconType.TRY_THATCH_BUTTON_ICON} fill="appFuscia.0" />
            }
                value={inputValue}
                onChange={(e) => setInputValue(e.currentTarget.value)}
                placeholder="Ask anything about your trip..."
                w="100%"
                maw={700}
                classNames={{
                    input: 'rounded-full border border-black h-14 !pl-12 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]',
                    icon: 'ml-3',
                }}
                rightSection={
                    <button
                        // disabled={!value}
                        type="submit"
                        className="flex justify-center items-center h-10 w-10 rounded-[50%] absolute right-2 bottom-2 bg-black"
                        style={{
                            // opacity: value ? 1 : 0.5,
                        }}
                        onClick={() => handleQuerySubmit(inputValue)}
                    >
                        <SvgIconNative type={SvgIconNativeType.ARROW_UP_ICON} />
                    </button>
                }
            />

            <div className="overflow-x-clip relative max-w-full" ref={containerRef}>
                <div className="flex flex-col gap-2 relative z-0 transition-transform duration-500 w-auto" style={{ transform: `translate(-${contentOffset}px, 0px)` }} ref={contentRef}>
                    {location && <>
                        <div className="flex gap-2">
                            <PromptChip text={`Suggest a local expert to plan my trip`} onClick={handleQuerySubmit} />
                            <PromptChip text={`Suggest an unique itinerary for my ${location} trip`} onClick={handleQuerySubmit} />
                            <PromptChip text={`What foods do ${location} locals eat`} onClick={handleQuerySubmit} />
                        </div>
                        <div className="flex gap-2">
                            <PromptChip text={`What are some true hidden gems in ${location}`} onClick={handleQuerySubmit} />
                            <PromptChip text={`Help me brainstorm trip ideas for ${location}`} onClick={handleQuerySubmit} />
                            <PromptChip text={`Help me plan a family-friendly trip to ${location}`} onClick={handleQuerySubmit} />
                        </div>
                    </>}
                    {!location && <>
                        <div className="flex gap-2">
                            <PromptChip text={`Recommend a hotel for my trip`} onClick={handleQuerySubmit} />
                            <PromptChip text={`Match me with a local expert to chat with`} onClick={handleQuerySubmit} />
                        </div>
                        <div className="flex gap-2">
                            <PromptChip text={`Brainstorm destination ideas`} onClick={handleQuerySubmit} />
                            <PromptChip text={`Suggest a unique Thatch itineries for my trip`} onClick={handleQuerySubmit} />
                        </div>
                    </>}
                </div>
                {contentOffset > 0 && <div className="absolute top-0 left-0 h-full w-14 bg-gradient-to-l from-app-paper/0 to-app-paper flex justify-center items-center">
                    <button className="cursor-pointer" onClick={() => scrollableContent('left')}>
                        <IconChevronLeft />
                    </button>
                </div>}
                {contentOffset < scrollWidth && <div className="absolute top-0 right-0 h-full w-14 bg-gradient-to-r from-app-paper/0 to-app-paper flex justify-center items-center">
                    <button className="cursor-pointer" onClick={() => scrollableContent('right')}>
                        <IconChevronRight />
                    </button>
                </div>}
            </div>

        </div>
    )
}

function PromptChip({ text, onClick }: { text: string, onClick: (text: string) => void }) {
    return (
        <div className="bg-white/75 flex h-10 justify-center items-center gap-2 border background-white px-4 py-2 rounded-full border-solid border-[rgba(0,0,0,0.10)] hover:bg-white hover:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] transition-all cursor-pointer shrink-0" onClick={() => onClick(text)}>
            <Typography variant="body3" color="appBlack.0">
                {text}
            </Typography>
        </div>
    )
}